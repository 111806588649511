<template>
  <div>
    <p>I'm Marc, a {{_calculateAge()}} years old IoT student.</p>
    <p>I speak German, English, a very tiny amount of French, as well as {{$store.state.languagesText}}</p>
    <p>
      <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
    </p>
  </div>
</template>

<script>
  export default {
    methods: {
      _calculateAge() {
        const birthday = new Date(984013200000);
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      }
    }
  }
</script>

<style scoped>
p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}
</style>